import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import { intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';

import { FieldPhoneNumberInput } from '../../components';

const UserFieldPhoneNumber = props => {
  const { rootClassName, fieldKey, className, formId, formName, userTypeConfig, intl } = props;

  const { displayInSignUp, required } = userTypeConfig?.phoneNumberSettings || {};
  const isDisabled = userTypeConfig?.defaultUserFields?.phoneNumber === false;
  const isAllowedInSignUp = displayInSignUp === true;

  if (isDisabled || !isAllowedInSignUp) {
    return null;
  }

  const isRequired = required === true;
  const validateUrlField = (urlValidator, isRequired) => (value) => {
    if (!value) {
      return isRequired
        ? (validators.required(requiredMessage || defaultRequiredMessage)(value))
        : undefined
    }
    return urlValidator(value);
  };


  const phoneValid = validators.phoneNumberFormatValid(
    intl && intl.formatMessage({
      id: 'SignupForm.phoneNumberInvalid',
    })
  )

  const getUrlFieldValidation = (fieldKey) => {
    const validateUrlFieldMapper = {
      'store_phone_number': validateUrlField(phoneValid, isRequired),
    }
    return validators.composeValidators(validateUrlFieldMapper[fieldKey])
  }

  return (
    <FieldPhoneNumberInput
      className={classNames(className, { [rootClassName]: !!rootClassName })}
      type="tel"
      id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
      name="phoneNumber"
      label={`${intl.formatMessage({
        id: `${formName}.phoneNumberLabel`,
      })}${isRequired ? '*' : ''}`}
      placeholder={intl.formatMessage({
        id: `${formName}.phoneNumberPlaceholder`,
      })}
      validate={getUrlFieldValidation(fieldKey)}
    />
  );
};

UserFieldPhoneNumber.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
};

UserFieldPhoneNumber.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  formName: string.isRequired,
  userTypeConfig: propTypes.userType.isRequired,
  intl: intlShape.isRequired,
};

export default UserFieldPhoneNumber;
